<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import errorHandler from '@/helpers/errorHandler'
import { validationMixin } from "vuelidate";
import { required } from 'vuelidate/lib/validators'
// import moment from 'moment';

/**
 * Starter component
 */
export default {
  page: {
    title: "Announcement Form ",
  },
  validations:{
    form:{
      announcement: {
        required,
      },
    }
  },
  data() {
    return {
      mixins: [validationMixin],
      title: "Announcement",
      items: [
        {
          text: "Work center",
          href: "/home",
        },
        {
          text: "Announcements",
          href: "/announcements",
        },
        {
          text: "Edits",
          active: true,
        },
      ],
      backendError: false,
      type: null,
      form:{
        announcement: null,
        date_time: null,
        year: null,
        id: null
      },
      yearError: null,
      submit: null,
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  computed:{
    years(){
      return this.$store.getters['option/years'];
    },
  },
  methods:{
    handleSubmit() {
      this.submit = true;
      this.$v.$touch();
      if(!this.$v.$anyError){
        // requests for the create
        this.form.date_time = Date.now();
        this.$store.dispatch("announcement/addAnnouncement",this.form).then(
           (res)=>{
            this.$router.push('/announcements');
          }
        ).catch((err)=>{
          errorHandler.methods.error(err);
        });
      }
    },
    handleUpdate(){
      this.submit = true;
      this.$v.$touch();
      if(!this.$v.$anyError){
        // requests for the create
        this.$store.dispatch("announcement/editAnnouncement",this.form).then(
           ()=>{
            this.$router.push('/announcements');
          }
        ).catch((err)=>{
          this.backendError=true;
        });
      }
    },
  },
  async created(){
    await this.$store.dispatch('option/getYearOption').then( ()=>{}).catch( err => {
        errorHandler.methods.errorMessage(err);
      });
    if(this.$route.params.id){
        this.type = 'edit';
        await this.$store.dispatch('announcement/getAnnouncmentInfo',this.$route.params.id).then( token =>{
            this.form.announcement = token.announcement;
            this.form.year = token.educational_year.id;
            this.form.id = this.$route.params.id;
            this.form.date_time = token.date_time;
        }).catch( err =>{
            errorHandler.methods.error(err);
        })
    }else{
        this.type = 'create';
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-body">
            <div>
                <div>
                  <div>
                    <h2 v-if="type == 'create'">Create Announcement</h2>
                    <h2 v-if="type == 'edit'">Edit Announcement</h2>
                    <hr>
                  </div>
                <form action="#" @submit.prevent="handleSubmit">
                <div class="row">
                    <div class="col-md-6" >
                      <b-form-group label="Year:" label-for="input-C" >
                        <b-form-select v-model="form.year" :options="this.years"></b-form-select>
                        <b-form-invalid-feedback id="input-2-live-feedback">This is a required field.</b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                    <b-form-group
                        id="input-group-2"
                        label="Announcement :"
                        label-for="input-D"
                    >
                        <input v-model="form.announcement" type="text" name="name" class="form-control" :class="{ 'is-invalid': submit && $v.form.announcement.$error }" placeholder="Enter announcement text" />
                        <div v-if="submit && $v.form.announcement.$error" class="invalid-feedback">
                            <span v-if="!$v.form.announcement.required">This name is required.</span>
                        </div>
                    </b-form-group>
                    </div>
                </div>
                </form>
                </div>
                <hr />
                <div class="float-right">
                <button class="btn btn-outline-success" type="submit" @click="handleSubmit" v-if="type == 'create'">Submit</button>
                <button class="btn btn-outline-success" type="submit" @click="handleUpdate" v-if="type == 'edit'">Update</button>
                <b-button variant="outline-danger" class="ml-2 aline-right" @click="$router.go(-1)"
                >Cancel</b-button
                >
                </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
#input-group-1 {
  display: inline;
}
</style>